<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<div class="row min-height">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
        <noticias-alumnos-showcasebox></noticias-alumnos-showcasebox>
    </div>
    <div class="col-md-4">
        <evaluacion-por-generador [evaluacionTipo]="'Anuies'" [enableRepeat]="enableRepeat"></evaluacion-por-generador>
        <br />
    </div>
    <div class="col-md-4">
        <smowl-frame
            [courseName]="'eval01'"
            [modality]="'test'"
            [lang]="'es'"
            [courseContainer]="'anuies01'"
            [disablePhotoCapture]="true"
        ></smowl-frame>
    </div>
</div>
<noticias-modal [perfiles]="'todos,alumnos,alumnos libres,alumnos nomina'"></noticias-modal>