import {
    RealizarEnsayoComponent,
    EvaluacionesMultiplesCompartidasComponent,
    GenerarEnsayoMultipleComponent,
    EvaluacionesCorregirComponent,
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EnsayosRecomendadosComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ImprimirEnsayoComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { ResultadosEnsayoAnuiesComponent } from "./resultados_ensayo_anuies.component"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ProgresoAlumnoComponent, EstadisticasAlumnoComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableSmowlFrame: true,
                    theme: "theme-cb",
                    enableBtnMarkAsUncertain: true,
                    enablePreguntasId: false,
                    enableDudasBtn: true,
                    textoCompletar: "Sí, deseo terminar la evaluación"
                }
            }
        ]
    },
    // {
    //     path: "ensayos/:id/imprimir",
    //     component: ImprimirEnsayoComponent,
    //     canActivate: [TokenGuard, AuthGuard, TokenGuard]
    // },
    // {
    //     path: "evaluaciones/recomendadas",
    //     component: EnsayosRecomendadosComponent,
    //     canActivate: [TokenGuard, AuthGuard]
    // },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoAnuiesComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
    // { path: "evaluaciones/instancias/:id", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "evaluaciones/:id/realizar", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] },
    // {
    //     path: "evaluaciones/:id/imprimir",
    //     component: ImprimirEnsayoComponent,
    //     canActivate: [TokenGuard, AuthGuard, TokenGuard]
    // },
    // { path: "evaluaciones/compartidas", component: EvaluacionesCompartidasComponent },
    // { path: "ensayos_breves", component: EnsayosMensualesComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "evaluaciones/:id/corregir", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] },
    // //Calugas
    // {
    //     path: "saber/generar_simulacro/:asignatura_id",
    //     component: GenerarEnsayoComponent,
    //     canActivate: [TokenGuard, AuthGuard]
    // },
    // { path: "saber/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "saber/mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "saber/resultados", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "saber", component: HomeSaberComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "saber/compartidas", component: EvaluacionesCompartidasComponent },

    // {
    //     path: "saber11",
    //     canActivate: [TokenGuard, AuthGuard],
    //     children: [
    //         { path: "", component: HomeSaber11Component, canActivate: [TokenGuard, AuthGuard] },
    //         // simulacros multiples
    //         {
    //             path: "generar_simulacro",
    //             component: GenerarEnsayoMultipleComponent,
    //             canActivate: [TokenGuard, AuthGuard]
    //         },
    //         { path: "compartidos", component: EvaluacionesMultiplesCompartidasComponent },
    //         // simulacros por asignatura
    //         {
    //             path: "generar_simulacro/:asignatura_id",
    //             component: GenerarEnsayoComponent,
    //             canActivate: [TokenGuard, AuthGuard]
    //         },
    //         { path: "estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    //         { path: "mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    //         { path: "resultados", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] },
    //         {
    //             path: "compartidos/asignatura",
    //             component: EvaluacionesCompartidasComponent,
    //             canActivate: [TokenGuard, AuthGuard]
    //         }
    //     ]
    // },

    // {
    //     path: "pruebas/generar_prueba",
    //     component: GenerarEnsayoComponent,
    //     canActivate: [TokenGuard, AuthGuard]
    // },
    // { path: "pruebas/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "pruebas/mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "pruebas/resultados", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "pruebas/compartidas", component: EvaluacionesCompartidasComponent },
    // { path: "pruebas", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }
]

export const routing = RouterModule.forChild(routes)
