import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute, Router } from "@angular/router"
import { EvaluacionInstancia } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "resultados_ensayo_anuies.component.html"
})
// export class ResultadosEnsayoComponent implements OnInit, OnDestroy {
export class ResultadosEnsayoAnuiesComponent {
    // evaluacionInstanciaId: number
    // evaluacionInstancia: EvaluacionInstancia
    // sub: Subscription
    // rutaVerEvaluaciones: string

    // @Output() onEvaluacionReady: EventEmitter<any> = new EventEmitter<any>()

    // constructor(protected route: ActivatedRoute) {}
    constructor(protected router: Router, protected evaluationLayoutService: EvaluationLayoutService) {}

    // ngOnInit() {
    //     this.sub = this.route.params.subscribe(params => {
    //         this.evaluacionInstanciaId = +params["id"]
    //     })
    // }

    // onEvaluacionInstanciaReady(evaluacionInstancia) {
    //     this.evaluacionInstancia = evaluacionInstancia
    //     this.rutaVerEvaluaciones =
    //         "/" + this.evaluacionInstancia.evaluacion.evaluacion_tipo.evaluacion_tipo + "/resultados"
    // }

    // ngOnDestroy() {
    //     this.sub.unsubscribe()
    // }

    volver() {
        this.router.navigate(["/home"])
    }

    ngOnInit() {
        this.evaluationLayoutService.setOptions({ singleColumn: true })
    }
}
