import { Component, Input, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import {
    GeneradorInstrumento,
    GeneradorInstrumentos,
    TipoInstrumento,
    TipoInstrumentos
} from "@puntaje/nebulosa/api-services"
import { SessionService } from "@puntaje/shared/core"
import {
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancias,
    EvaluacionTiempo,
    EvaluacionTiempos
} from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "./evaluacion-por-generador.component.html",
    selector: "evaluacion-por-generador",
    styleUrls: ["./evaluacion-por-generador.component.scss"]
})
export class EvaluacionPorGeneradorComponent implements OnInit {
    @Input() evaluacionTipo: string
    @Input() enableRepeat: boolean = false
    generadorInstrumento: GeneradorInstrumento

    showGenerador: boolean
    showPruebaCompletada: boolean = false
    constructor(
        protected router: Router,
        protected evaluacionesService: Evaluaciones,
        protected generadorInstrumentoService: GeneradorInstrumentos,
        protected tipoInstrumentoService: TipoInstrumentos,
        protected evaluacionInstanciaService: EvaluacionInstancias,
        protected sessionService: SessionService,
        protected evaluacionTiempos: EvaluacionTiempos
    ) {}

    async ngOnInit() {
        const params = {
            tipo_instrumento: {
                tipo_instrumento: this.evaluacionTipo
            },
            sort: [{ field: "created_at", criteria: "desc" }],
            include: "asignatura_plataforma",
            with_tipo_instrumento: 1
        }
        const generadores = await this.generadorInstrumentoService.where(params)
        if (generadores.length > 0) {
            this.generadorInstrumento = generadores[0]
        }
        let instancias: any = 0
        this.evaluacionInstanciaService.enableIgnoreModel()
        instancias = await this.getCantidadInstancias()
        this.evaluacionInstanciaService.disableIgnoreModel()
        this.showPruebaCompletada = instancias > 0 && !this.enableRepeat
        this.showGenerador = this.generadorInstrumento && (this.enableRepeat || instancias == 0)
    }

    makeEvaluacion() {
        const evaluacion = new Evaluacion() as any
        evaluacion.tiempo = this.generadorInstrumento.tiempo
        evaluacion.asignatura_id = this.generadorInstrumento.asignatura_plataforma.asignatura_id
        evaluacion.generador_instrumento_id = this.generadorInstrumento.id
        evaluacion.numero_preguntas = this.generadorInstrumento.numero_preguntas
        // evaluacion.clasificaciones_id = this.clasificac
        evaluacion.evaluacion_tipo = this.generadorInstrumento.tipo_instrumento.tipo_instrumento

        return this.evaluacionesService.save(evaluacion)
    }

    async generarEvaluacion() {
        let evalExistente = await this.checkExistEvaluacion()
        if (evalExistente) {
            this.router.navigate(["/ensayos", evalExistente, "realizar"])
        } else {
            this.makeEvaluacion().then((evaluacion: Evaluacion) => {
                this.router.navigate(["/ensayos", evaluacion.id, "realizar"])
            })
        }
    }

    getCantidadInstancias() {
        const params = {
            evaluacion_instancia: {
                usuario_id: this.sessionService.getUserData().id,
                oficial: 1
            },
            instrumento: {
                generador_instrumento_id: this.generadorInstrumento.id
            },
            sort: [
                {
                    field: "created_at",
                    criteria: "asc"
                }
            ],
            count: 1
        }

        return this.evaluacionInstanciaService.where(params)
    }

    async checkExistEvaluacion() {
        const usuarioId = this.sessionService.getUserData().id
        const params = {
            instrumento: {
                generador_instrumento_id: this.generadorInstrumento.id,
                usuario_id: usuarioId
            },
            sort_by: "created_at",
            order: "desc"
        }

        return this.evaluacionesService.where(params).then((evaluaciones: Evaluacion[]) => {
            if (evaluaciones.length > 0) {
                const params = {
                    evaluacion_tiempo: {
                        evaluacion_id: evaluaciones[0].id,
                        usuario_id: usuarioId
                    },
                    sort_by: "created_at",
                    order: "desc"
                }
                return this.evaluacionTiempos.where(params).then((tiempos: EvaluacionTiempo[]) => {
                    if (tiempos.length > 0) {
                        let evaluacionTiempo = tiempos[0]
                        let tiempoDiferencia =
                            new Date((evaluacionTiempo as any).datetime_now).getTime() - new Date().getTime()
                        let segundosRestantes = Math.max(
                            evaluacionTiempo.tiempo_limite -
                                ~~(
                                    (new Date().getTime() +
                                        tiempoDiferencia -
                                        new Date(evaluacionTiempo.created_at).getTime()) /
                                    1000
                                ),
                            0
                        )
                        if (segundosRestantes > 0) {
                            return evaluaciones[0].id
                        }
                        return null
                    }
                    return null
                })
            }
            return null
        })
    }
}
