import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, Input, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent implements OnInit {
    @Input() pagina: string = "home"
    isPro = false
    dias = false
    dias_faltantes = 0
    frase: string = ""
    mailSinConfirmar: boolean = false
    enableRepeat: boolean = false
    //@ViewChild('loadingLayout') loadingLayout: LoadingLayoutComponent;
    asignaturasBoxInsteadPlanClase = false

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private authService: AuthService,
        protected sessionService: SessionService,
        protected usuariosService: Usuarios,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.isPro = this.sessionService.isPro()
        let userId = this.sessionService.getUserData()["id"]
        this.enableRepeat = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(userId)
        this.setMailSinConfirmar()

        this.loggedLayoutService.setOptions({ freeContentStyle: true, noTitleBar: true })
    }

    setMailSinConfirmar() {
        const confirmed_at = this.sessionService.getConfirmedAt()
        if (!confirmed_at) {
            this.mailSinConfirmar = true
        }
    }
}
