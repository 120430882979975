<footer>
    <!-- <div class="top-footer"></div>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-3 footer-col">
                <h3>Sobre este sitio</h3>
                <ul>
                    <li><a>Iniciar sesión</a></li>
                    <li><a>Registrarse para practicar</a></li>
                    <li><a>Políticas de seguridad</a></li>
                    <li><a>Términos y condiciones</a></li>
                </ul>
            </div>

            <div class="col-md-3 col-md-offset-6 col-sm-6 footer-col">
                <h3>Aprendolibre en otros países</h3>
                <ul>
                    <li><a routerLink="/landing">México</a></li>
                    <li><a>Ecuador</a></li>
                    <li><a>Colombia</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <a class="footer-logo" href="http://ogr.cl/">
            <img
                class="footer-logo"
                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                alt="PuntajeNacional.cl"
            />
        </a>
        <a class="footer-logo" routerLink="/landing">
            <img
                src="{{ config.app.assets.logoLargeLight }}"
                alt="{{ config.plataforma.info.companyName }}"
                title="{{ config.plataforma.info.companyName }}"
            />
        </a>
    </div> -->
</footer>
