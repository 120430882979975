<a *ngIf="showGenerador" class="evaluacion-card" title="Prueba Anuies" (click)="generarEvaluacion()">
    <ogr-icon name="pro" class="card-icon"></ogr-icon>
    <span class="inner-wrap">
        <span class="evaluacion">Prueba Anuies</span>
    </span>
    <span class="info-wrap">
        <span class="info preguntas">
            <fa name="pencil-square-o" class="icono"></fa>
            {{ generadorInstrumento.numero_preguntas }} Preg.
        </span>
        <span class="info tiempo" *ngIf="generadorInstrumento.tiempo">
            <fa name="clock-o" class="icono"></fa>
            {{ generadorInstrumento.tiempo }} min
        </span>
    </span>
    <span class="comenzar">
        Comenzar
        <fa name="caret-right" class="icono"></fa>
    </span>
</a>
<div *ngIf="showPruebaCompletada" class="evaluacion-card no-pointer">
    <span class="inner-wrap">
        <span class="evaluacion">Prueba Anuies</span>
    </span>
    <span class="info-wrap"></span>
    <span class="comenzar">Completada.</span>
</div>
